<template>
  <nav class="tabs">
    <button
      v-for="(tab, index) in tabs"
      :key="tab.id"
      @click="changeTab(index)"
      :ref="'tab' + index"
      :class="[ 'tab', `tab_size_${size}`, { 'tab_type_active' : currentTabIndex === index } ]"
    >
      <span class="tab__content">
        <p v-if="size === 'M'" class="label label_size_M">{{ capitalize(tab.title) }}</p>
        <p v-if="size === 'S'" class="label label_size_S">{{ capitalize(tab.title) }}</p>
        <kaz-badge
          v-if="tab.badge"
          :size="size"
          :label="tab.badgeLabel"
          :appearance="tab.badgeAppearance"
        />
      </span>
    </button>
    <div
      class="tabs__indicator" 
      :style="{ transform: `translateX(${tabPosition}px)`, width: `${tabWidth}px` }"
    ></div>
  </nav>
</template>

<script>
import KazBadge from '@/components/KazUI/atoms/badge'

export default {
  name: 'tabs',
  components:{
    KazBadge
  },
  props: {
    tabs: {
      type: Array,
      required: true,
      validator: (value) => {
        // checks only first item i.e it's necessary but not sufficient condition
        if (!value.length
          || typeof value[0] !== 'object'
          || !value[0].hasOwnProperty('title')
        ) {
          return false;
        }
        return true
      }
    },
    initIndex: {
      type: Number,
      default: -1
    },
    size:{
      type: String,
      default: 'M'
    }
  },
  data() {
    return {
      currentTabIndex: null,
      tabWidth: 0,
      tabPosition: 0,
    };
  },
  methods: {
    changeTab(tabIndex) {
      this.currentTabIndex = tabIndex;
      this.calculateTabProperties(tabIndex);
      this.$emit('input', tabIndex);
    },
    calculateTabProperties(tabIndex) {
      this.$nextTick(() => {
        const tabElement = this.$refs['tab' + tabIndex][0];
        const rect = tabElement.getBoundingClientRect();
        this.tabWidth = rect.width;
        this.tabPosition = rect.left - tabElement.offsetParent.getBoundingClientRect().left;
        // console.log('tabs:', tabElement, tabElement.offsetParent);
        // console.log('geom:', tabElement.getBoundingClientRect(), tabElement.offsetParent.getBoundingClientRect());
      });
    },
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  },

  created() {
    if (!this.tabs.length) return;
    this.currentTabIndex = this.initIndex === -1 ? 0 : this.initIndex;
  },

  mounted() {
    if (this.currentTabIndex !== null) {
      setTimeout(() => {
        this.calculateTabProperties(this.currentTabIndex);
      }, 0);
    }
  }
};
</script>

<style scoped>
.tabs {
  position: relative;
  display: flex;
  flex-direction: row;
  width: fit-content;
  border-bottom: 1px solid var(--kaz-base-base-04);
}

.tab{
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.tab p {
  color: var(--kaz-textIcons-text-02);
  transition: color var(--kaz-transition-molecules);
}
.tab:hover p {
  color: var(--kaz-textIcons-text-01);
}

.tab_size_M {
  padding: 16px 0px;
}
.tab_size_S {
  padding: 12px 0px;
}


.tab_size_M:not(:first-child) {
  margin-left: 24px;
}
.tab_size_S:not(:first-child) {
  margin-left: 16px;
}

.tab_type_active p{
  color: var(--kaz-textIcons-text-01);
}

.tab__content{
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.tabs__indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background: var(--kaz-base-primary);
  transition: transform var(--kaz-transition-molecules);
}
</style>
