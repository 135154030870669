<template>
  <!-- Yandex.RTB R-A-13082046-1 -->
   <div id="yandex_rtb_R-A-13082046-1" ref="yandex-ads"></div>
</template>

<script>
export default {
  name: 'banner-yandex-ads',

  mounted() {
    try {
      window.yaContextCb.push(() => {
        Ya.Context.AdvManager.render({
          "blockId": "R-A-13082046-1",
          "renderTo": "yandex_rtb_R-A-13082046-1"
        });
      }); 
    } catch (error) {
      console.warn('Yandex Ads:', error);
    }
  }
}
</script>

<style scoped>
</style>
