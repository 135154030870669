<template>
  <div class="layout-wrapper">
    <header-common />
    <!-- <header-component/> -->
    <!-- <sub-header-component/> -->
    <div class="layout-wrapper__main">
      <!-- <aside v-if="showAccordion" class="container__aside">
        <accordion-sidebar :data="getGrades"/>
      </aside> -->
      <favorites-component
        v-if="showFavorites && sessionActive"
        :userHaveBooks="userHaveBooks"
      />
      <editor-intro v-if="showEditorIntro"/>
      <subject-class-filter v-if="showSubjectClassFilter" :data="getGrades"/>
      <div class="main-wrapper">
        <main class="main" ref="mainContent">
          <router-view/>
        </main>
        <aside v-if="showAside" class="aside">
          <BannerOriginalSolutions />
          <BannerBestAuthors />
          <BannerPathToVictory />
          <BannerYandexAds />
        </aside>
      </div>
    </div>
    <footer-component
      v-if="showFooter"
      :cooperation="cooperation"
      :documents="documents"
      :commonLinks="common"
      :socials="socials"
    />
    <!-- <cookie-notify/> -->
</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import SubjectClassFilter from '@/components/common/SubjectClassFilter.vue';
import HeaderComponent from '@/components/common/HeaderComponent';
import FooterComponent from '@/components/common/FooterComponent';
import FavoritesComponent from '@/components/common/FavoritesComponent.vue';
import SubHeaderComponent from '@/components/common/SubHeaderComponent';
import EditorIntro from '@/views/tasks/components/EditorIntro.vue';
import CookieNotify from '@/components/UI/CookieNotify.vue';
import HeaderCommon from '@/components/common/Header';
import BannerOriginalSolutions from '@/components/common/Banners/BannerOriginalSolutions.vue';
import BannerBestAuthors from '@/components/common/Banners/BannerBestAuthors.vue';
import BannerPathToVictory from '@/components/common/Banners/BannerPathToVictory.vue';
import BannerYandexAds from '@/components/common/Banners/BannerYandexAds.vue';

import { documents, common, cooperation, socials } from '@/components/common/FooterComponent/links';


export default {
  name: 'main-layout',

  components: {
    HeaderComponent,
    FooterComponent,
    SubjectClassFilter,
    FavoritesComponent,
    SubHeaderComponent,
    EditorIntro,
    CookieNotify,
    HeaderCommon,
    BannerOriginalSolutions,
    BannerBestAuthors,
    BannerPathToVictory,
    BannerYandexAds,
  },

  data() {
    return {
      books: [],
      clsSubjects: [],
      listSubjectsLevel: [],
      grades:[],
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      windowWidth: window.innerWidth,
      userHaveBooks: true,
      // for FooterComponent
      documents, common, cooperation, socials,
    }
  },

  computed: {
    ...mapGetters({
      getGrades: 'gradeData/getGrades',
      sessionActive: 'authData/sessionActive'
    }),

    showAccordion() {
      return this.$route.meta.layout === 'tasks'
    },

    showSubjectClassFilter() {
      if (this.$route.name === 'landing'
        || this.$route.path === '/personal-info'
        || this.$route.path === '/profile'
        || this.$route.path === '/subscriptions'
        || this.$route.path === '/profile/subscription'
        || this.$route.path === '/settings'
        || this.$route.path === '/finance'
        || (this.$route.name === 'tasks' && this.windowWidth < 992)
        || this.$route.path.startsWith('/cooperation')
        || this.$route.path.startsWith('/books/') && this.$route.path.includes('/tasks/')
        || this.$route.path.startsWith('/books/')

        || this.$route.path === '/lessons'
        || this.$route.path === '/lessons-course'
        || this.$route.path.startsWith('/lesson')
        || this.$route.name === 'lesson'
        || this.$route.name === 'lesson-task'

        || this.$route.path === '/school'
        || this.$route.path.startsWith('/denezhki')
        || this.$route.path === '/404'
        || this.$route.path === '/favorites'
        || this.$route.path === '/gpt'
        || this.$route.path.startsWith('/gpt/')
        || this.$route.path === '/promocodes'
        || this.$route.path === '/promocode-creation'
        || this.$route.path === '/content'
        || this.$route.path.includes('/editor/resh')
        || this.$route.path === '/payment-success'
        || this.$route.path === '/payment-failure'
        || this.$route.name === 'about'
      ) {
          return false
      }
      return true
    },
    showSubjectClassFilterLessons() {
      if (this.$route.path === '/lessons'
        || this.$route.path === '/lessons-course'
        || this.$route.path.startsWith('/lesson')
        || this.$route.name === 'lesson'
        || this.$route.name === 'lesson-task'
      ) {
          return true
      }
      return false
    },

    showFavorites() {
      if (this.$route.name === 'favorites'
        || this.$route.path === '/personal-info'
        || this.$route.path === '/profile'
        || this.$route.path === '/subscriptions'
        || this.$route.path === '/profile/subscription'
        || this.$route.path === '/settings'
        || this.$route.path === '/finance'
        || this.$route.path === '/lessons'
        || this.$route.path === '/school'
        || this.$route.path.startsWith('/cooperation')
        || this.$route.path.startsWith('/denezhki')
        || this.$route.path.startsWith('/books')
        // || this.$route.path.startsWith('/course')
        || this.$route.path === '/404'
        || this.$route.path === '/gpt'
        || this.$route.path === '/promocodes'
        || this.$route.path === '/promocode-creation'
        || this.$route.path.startsWith('/lesson')
        || this.$route.path === '/content'
        || this.$route.path.startsWith('/gpt/')
        || this.$route.path.includes('/editor/resh')
        || this.$route.path === '/payment-success'
        || this.$route.path === '/payment-failure'
        || this.$route.name === 'about'
      ) {
          return false
      }
      return true
    },

    showFooter(){
      if(
        this.$route.path.startsWith('/gpt') && this.windowWidth < 568
      ){
        return false
      }
      return true
    },

    showEditorIntro() {
      // return this.$route.path.includes('/editor/resh')
    },

    showAside() {
      // Hide aside on profile pages
      return this.$route.meta?.layout !== 'profile' && this.$route.name !== 'about';
    }
  },

  methods: {
    ...mapActions({
      fetchGrades: 'gradeData/fetchGrades',
      fetchCourses: 'courseData/fetchCourses',
      fetchUser: 'userData/fetchUser',
      fetchUserRoles: 'userData/fetchUserRoles',
    }),

    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    async getMenuGrades() {
      try {
        if (!this.getGrades.length) {
          await this.fetchGrades();
        }
        await this.fetchCourses();
      } catch(err) {
        console.error('Error in getMenuGrades:', err);
      }
    },

    async getUser() {
      try {
        await this.fetchUser();
      }
      catch(err) {
        console.log('Error in getUser:', err);
      }
    },

    async getRoles() {
      try {
        await this.fetchUserRoles();
      }
      catch(err) {
        console.log('Error in getRoles:', err);
      }
    },
  },

  async created() {
    await this.getMenuGrades();

    if (this.sessionActive) {
      console.warn('session is active, fetching user data...');
      await this.getUser();
      await this.getRoles();
    } else {
      console.warn('session is nonactive');
    }
    // console.log('BIG PROBLEM:', this.$getTokenInfo());
  },

  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  },
}
</script>

<style scoped>
@import url('./styles/base.css');
</style>
