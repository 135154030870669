<template>
  <card-background v-if="windowWidth > 992">
    <nav class="navigation">
      <ul class="navigation__list">
        <li
          class="navigation__point"
          v-for="(item, index) in subHeaderArray"
          :key="index"
          :class="{ 'navigation__point_active': isLinkActive(item.link) }"
        >
          <router-link :to="{ name: item.link.included[0]}" class="link">
            <div class="point__wrapper">
              <img class="point__icon" :src="getIconUrl(item.id)" :alt="item.title" />
              <div class="point__text-wrapper">
                <div class="text-wrapper__heading-wrapper">
                  <heading-size-h4 :style="{ color: isLinkActive(item.link) ? 'var(--background-red)' : 'var(--text-black)' }" :textWeight="'600'">{{ item.title }}</heading-size-h4>
                  <promo-tag v-if="item.tag.new"/>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.33594 10H16.6693M16.6693 10L11.6693 5M16.6693 10L11.6693 15" stroke="#D8473A" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <paragraph-size-extra-small :style="{ color: isLinkActive(item.link) ? 'var(--text-black)' : 'var(--text-secondary)' }">{{ item.description }}</paragraph-size-extra-small>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </nav>
  </card-background>


  <div class="card-background" v-if="windowWidth <= 992">
    <div class="subheader__wrapper">
      <div class="services__row">
        <div
          class="menu__point"
          v-for="(item, index) in subHeaderArray.slice(0,4)"
          :key="index"
          :class="{ 'menu__point_active': isLinkActive(item.link) }"
        >
          <router-link :to="{ name: item.link.included[0]}" class="link">
            <div class="point-wrapper">
              <div class="icon__background">
                <img class="icon" :src="getIconUrl(item.id)" :alt="item.title" />
              </div>
              <paragraph-size-small :textColor="'var(--text-black)'" :textWeight="'600'">{{ item.title }}</paragraph-size-small>
            </div>
          </router-link>
        </div>
      </div>

      <div v-if="showAllServices" class="services__row">
        <div
          class="menu__point"
          v-for="(item, index) in subHeaderArray.slice(4)"
          :key="index"
          :class="{ 'menu__point_active': isLinkActive(item.link) }"
        >
          <router-link :to="{ name: item.link.included[0]}" class="link">
            <div class="point-wrapper">
              <div class="icon__background">
                <img class="icon" :src="getIconUrl(item.id)" :alt="item.title" />
              </div>
              <paragraph-size-small :textColor="'var(--text-black)'" :textWeight="'600'">{{ item.title }}</paragraph-size-small>
            </div>
          </router-link>
        </div>
      </div>

      <button @click="toggleServices" class="services__show-button" v-if="subHeaderArray.length > 4">
        <div class="button-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M4.979 9.685C2.993 8.891 2 8.494 2 8C2 7.506 2.993 7.11 4.979 6.315L7.787 5.192C9.773 4.397 10.767 4 12 4C13.234 4 14.227 4.397 16.213 5.192L19.021 6.315C21.007 7.109 22 7.506 22 8C22 8.494 21.007 8.89 19.021 9.685L16.213 10.809C14.227 11.603 13.233 12 12 12C10.766 12 9.773 11.603 7.787 10.809L4.979 9.685Z" stroke="#D8473A" stroke-width="1.5"/>
            <path d="M5.766 10L4.979 10.315C2.993 11.109 2 11.507 2 12C2 12.493 2.993 12.89 4.979 13.685L7.787 14.809C9.773 15.603 10.767 16 12 16C13.234 16 14.227 15.603 16.213 14.809L19.021 13.685C21.007 12.891 22 12.493 22 12C22 11.507 21.007 11.11 19.021 10.315L18.234 10" stroke="#D8473A" stroke-width="1.5"/>
            <path d="M5.766 14L4.979 14.315C2.993 15.109 2 15.507 2 16C2 16.494 2.993 16.89 4.979 17.685L7.787 18.809C9.773 19.603 10.767 20 12 20C13.234 20 14.227 19.603 16.213 18.808L19.021 17.685C21.007 16.891 22 16.494 22 16C22 15.507 21.007 15.11 19.021 14.315L18.234 14" stroke="#D8473A" stroke-width="1.5"/>
          </svg>
          <paragraph-size-medium :textColor="'var(--text-black)'">
            {{ showAllServices ? 'Cкрыть сервисы' : 'Все сервисы' }}
          </paragraph-size-medium>
        </div>
        <svg v-if="!showAllServices" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7068 15.7083C12.5193 15.8958 12.265 16.0011 11.9998 16.0011C11.7347 16.0011 11.4803 15.8958 11.2928 15.7083L5.63582 10.0513C5.54031 9.95907 5.46413 9.84873 5.41172 9.72672C5.35931 9.60472 5.33172 9.4735 5.33057 9.34072C5.32942 9.20794 5.35472 9.07626 5.405 8.95337C5.45528 8.83047 5.52953 8.71882 5.62343 8.62492C5.71732 8.53103 5.82897 8.45678 5.95187 8.4065C6.07476 8.35622 6.20644 8.33092 6.33922 8.33207C6.472 8.33322 6.60322 8.36081 6.72522 8.41322C6.84723 8.46563 6.95757 8.54181 7.04982 8.63732L11.9998 13.5873L16.9498 8.63732C17.1384 8.45516 17.391 8.35437 17.6532 8.35665C17.9154 8.35892 18.1662 8.46409 18.3516 8.6495C18.537 8.83491 18.6422 9.08572 18.6445 9.34792C18.6468 9.61011 18.546 9.86272 18.3638 10.0513L12.7068 15.7083Z" fill="#97989C"/>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2932 8.29168C11.4807 8.10421 11.735 7.99889 12.0002 7.99889C12.2653 7.99889 12.5197 8.10421 12.7072 8.29168L18.3642 13.9487C18.4597 14.0409 18.5359 14.1513 18.5883 14.2733C18.6407 14.3953 18.6683 14.5265 18.6694 14.6593C18.6706 14.7921 18.6453 14.9237 18.595 15.0466C18.5447 15.1695 18.4705 15.2812 18.3766 15.3751C18.2827 15.469 18.171 15.5432 18.0481 15.5935C17.9252 15.6438 17.7936 15.6691 17.6608 15.6679C17.528 15.6668 17.3968 15.6392 17.2748 15.5868C17.1528 15.5344 17.0424 15.4582 16.9502 15.3627L12.0002 10.4127L7.05018 15.3627C6.86158 15.5448 6.60898 15.6456 6.34678 15.6434C6.08458 15.6411 5.83377 15.5359 5.64836 15.3505C5.46295 15.1651 5.35778 14.9143 5.35551 14.6521C5.35323 14.3899 5.45402 14.1373 5.63618 13.9487L11.2932 8.29168Z" fill="#D8473A"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import CardBackground from '@/components/UI/card/CardBackground.vue';
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';
import ParagraphSizeSmall from '@/components/UI/texts/paragraphs/ParagraphSizeSmall.vue';
import ParagraphSizeExtraSmall from '@/components/UI/texts/paragraphs/ParagraphSizeExtraSmall.vue';
import HeadingSizeH4 from '@/components/UI/texts/headings/HeadingSizeH4.vue';
import PromoTag from '@/components/UI/tags/PromoTag.vue';

import subHeaderArray from './utils.js';

export default {
  name: 'navbar-actions',
  components:{
    ParagraphSizeSmall,
    ParagraphSizeExtraSmall,
    ParagraphSizeMedium,
    CardBackground,
    HeadingSizeH4,
    PromoTag
},
  data() {
    return {
      subHeaderArray,
      windowWidth: window.innerWidth,
      showAllServices: false
    };
  },
  methods: {
    getIconUrl(iconId) {
      const icons = require.context('@/assets/images/subheader-icons/', false, /\.svg$/);
      return require(`@/assets/images/subheader-icons/icon_id_${iconId}.svg`);
    },
    isLinkActive(link) {
      const hasIncluded = this.$route.matched.some(route => link?.included?.includes(route.name));
      const hasExcluded = link?.excluded?.includes(this.$route.name);
      return hasIncluded && !hasExcluded
      if (link !== '/') {
        // console.log(this.$route);
        return this.$route.path.startsWith(link)
      }
      return this.$route.path === link;
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    toggleServices() {
      this.showAllServices = !this.showAllServices;
    }
  },
  computed:{
    displayedItems() {
      if (this.windowWidth <= 992 && !this.showAllServices) {
        return this.subHeaderArray.slice(0, 4);
      } else {
        return this.subHeaderArray;
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }
};
</script>

<style scoped>
.card-background{
  width: 100%;
  background-color: var(--white);
  padding: 16px 32px 16px 32px;
  border-radius: 8px;
  box-shadow: 4px 0px 16px 0px rgba(74, 74, 74, 0.15);
}
.subheader__wrapper{
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 64px;
}
.link{
  text-decoration: none;
}
.menu__point{
  padding-bottom: 14px;
  border-bottom: 2px solid var(--white);
  max-width: 100px;
}
.menu__point:hover{
  border-bottom: 2px solid var(--text-secondary);
}
.menu__point_active{
  border-bottom: 2px solid var(--background-red);
}
.point-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.icon__background{
  padding: 12px;
  background: rgba(131, 50, 42, 0.05);
  border-radius: 8px;
}
.services__row{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.services__show-button{
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 12px 0px;
  width: 100%;
}

.button-wrapper{
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.icon{
  width: 32px;
  height: 32px;
}

.navigation__list{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
}
.navigation__point{
  border-radius: 8px;
  transition: .3s ease-in-out;
}
.navigation__point_active{
  outline: 1px solid var(--background-red);
  background-color: rgba(216, 71, 58, 0.05);
  transition: .3s ease-in-out;
}

.navigation__point:hover{
  outline: 1px solid var(--background-red);
  transition: .3s ease-in-out;
}
.text-wrapper__heading-wrapper :deep(svg) {
  opacity: 0;
}

.point-wrapper:hover svg {
  opacity: 1;
}

.point__wrapper{
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  max-width: 280px;
}

.point__icon{
  width: 24px;
  height: 24px;
}

.point__text-wrapper{
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.text-wrapper__heading-wrapper{
  display: flex;
  flex-direction: row;
  gap: 4px;
}

@media screen and (max-width:992px) {
  .card-background{
    padding: 16px 16px 0px 16px;
  }
  .subheader__wrapper{
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  .icon__background{
    padding: 10px;
  }
  .menu__point{
    padding-bottom: 8px;
    width: 92px;
  }
  .icon{
    width: 32px;
    height: 32px;
  }
}
</style>
