const composeRoutes = function(routesArray, componentsRoot, layout) {
  return routesArray.map(route => ({
    ...route,
    meta: {layout: layout},
    component: () => import(`${componentsRoot + route.component}`)
  }))
}

const mergeRoutes = function(routes) {
  const finalRoutes = []
  for (const route in routes) {
    finalRoutes.pop(route)
  }
  return finalRoutes
}

const DEFAULT_NAME = 'Казатель Решения'

const routeTitles = {
  'main': 'Главная',
  'task': 'ГДЗ без ошибок, готовые домашние задания и решебник для школьных учебников 1-11 классов от Казатель Решения',
  'tasks': 'ГДЗ без ошибок, готовые домашние задания и решебник для школьных учебников 1-11 классов от Казатель Решения',
  'kazatel-lessons': 'Уроки от Казатель',
  'online-school': 'Онлайн-школа от Казатель',
  'landing': 'Добро пожаловать в Казатель',
  'monetization': 'Казатель Денежки',
  'personal-info': 'Личный кабинет',
  'user-profile': 'Личный кабинет',
  'user-subscription': 'Настройки подписки',
  'subscriptions': 'Подписка на Казатель',
  'favorites': 'Мой рюкзак',
  'editor-resh': 'Мой контент',
  'reports': 'Отчеты по агентскому договору',
  'report-table': 'Детальный отчет по агентскому договору',
  'stats': 'Казатель Денежки статистика',
  'promocodes': 'Мои промокоды',
  'promocode-creation': 'Создать новый промокод',
  'book-content': DEFAULT_NAME,
  'course': DEFAULT_NAME,
  'lesson': 'Казатель Уроки',
  'lesson-root': 'Казатель Уроки',
  'lessons': 'Онлайн уроки: русский язык, алгебра, английский, история',
  'moderation-list': 'Модерация',
  'moderation-order': 'Детали по модерации',
  'moderation-reports': 'Отчеты по модерации',
  'book-creation': 'Создание пособия',
  'structure-edit': 'Редактирование структуры',
  'structure-edit-v2': 'Редактирование структуры',
  'book-list': 'Редактирование пособий',
  'book-search': 'Поиск пособий',
  'login': 'Вход в Казатель',
  'register': 'Регистрация | Казатель',
  'email-confirm': 'Подтверждение почты',
  'password-reset': 'Сброс пароля',
  'password-reset-success': 'Сброс пароля',
  'password-confirm': 'Подтверждение пароля',
  'user-agreement': 'Пользовательское соглашение',
  'personal-data': 'Обработка персональных данных',
  'user-oferta': 'Договор-оферта',
  'agent-oferta': 'Оферта агентского договора',
  'about': 'О нас',
}

export {
  composeRoutes,
  routeTitles,
}